import React, { useState, createContext } from 'react';

export const ResponseContext = createContext();

export function ResponseProvider({ children }) {
    const [responseMessage, setResponseMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const clearResponseMessage = () => {
        setResponseMessage('');
    };
    const clearErrorMessage = () => {
        setErrorMessage('');
    };

    return (
        <ResponseContext.Provider value={{ responseMessage, setResponseMessage, clearResponseMessage, errorMessage, setErrorMessage, clearErrorMessage }}>
            {children}
        </ResponseContext.Provider>
    );
}

