import React, { useState } from "react";
import { navigate, } from "hookrouter";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);

  function handleLogin(e) {
    e.preventDefault();

    const payload = {
      username: email,
      password: password,
      endpoint: "v1/login/verify"
    };

    fetch('/admin/postData.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        if (data.success === 1) {
          window.location.href = '/app/users';
          const defaultNonce = data.response.default;

          if (data.response.time_offset !== undefined) {
            console.log('Time Offset:', data.response.time_offset);
            // time_offset in localStorage
            localStorage.setItem('time_offset', data.response.time_offset);
          }

          return fetch('admin/getData.php', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ defaultNonce })
          });

        } else {
          setError(true);
          throw new Error('Login failed');
        }
      })
      .then(sessionResponse => sessionResponse.json())
      .then(sessionData => {
        window.location.href = '/app/users';
      })
      .catch((error) => {
        console.error('Error:', error);
        setError(true);
      });
  }



  return (
    <div className="flex items-center justify-center h-screen">
      <form
        onSubmit={handleLogin}
        className="flex flex-col self-center px-8 pt-6 pb-8 m-12 mb-4 bg-white rounded shadow-md"
      >
        <div>{error && "Your credentials are incorrect"}</div>
        <div className="mb-4">
          <label
            className="block mb-2 text-sm font-bold text-grey-darker"
            for="username"
          >
            Username
          </label>
          <input
            className="w-full px-3 py-2 border rounded shadow appearance-none text-grey-darker"
            id="username"
            onChange={(e) => setEmail(e?.target?.value)}
            type="text"
            placeholder="Username"
          />
        </div>
        <div className="mb-6">
          <label
            className="block mb-2 text-sm font-bold text-grey-darker"
            for="password"
          >
            Password
          </label>
          <input
            className="w-full px-3 py-2 mb-3 border rounded shadow appearance-none border-red text-grey-darker"
            id="password"
            type="password"
            onChange={(e) => setPassword(e?.target?.value)}
            placeholder="******************"
          />
          <p className="text-xs italic text-red">Please choose a password.</p>
        </div>
        <div className="flex items-center justify-between">
          <button
            className="px-4 py-2 mr-4 font-medium text-white rounded-lg bg-supernova hover:bg-yellowsea"
            type="submit"
          >
            Sign In
          </button>
          {/* <a
            className="inline-block text-sm font-bold align-baseline text-blue hover:text-blue-darker"
            href="#"
          >
            Forgot Password?
          </a> */}
        </div>
      </form>
    </div>
  );
}

