
import React, { useState, useEffect, useContext } from 'react';
import "react-dates/lib/css/_datepicker.css";
import { motion } from "framer-motion";
import { AddnewIcon } from '../assets/icons/hiking';


import { ResponseContext } from './ResponseContext';



export function Transactions() {


  const [searchTerm, setSearchTerm] = useState('');

  const [isFormVisible, setIsFormVisible] = useState(true);
  const [selectedCustomerNonce, setSelectedCustomerNonce] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    const eid = "MTY1YTU0YzI2YTY4ODI=";
    const type = "customer";
    const nonce = "ef9da17f92274ec58a21fbcfc3885976";
    const search = ""

    function protocolpluspath() {
      return window.location.protocol + '//' + window.location.host + '/';
    }

    const url = protocolpluspath() + 'admin/getData.php?eid=' + eid + '&type=' + type + '&search=' + encodeURIComponent(searchTerm) + '&nonce=' + nonce;

    fetch(url)
      .then(response => response.json())
      .then(data => setData(data.data.response))
      .catch(error => console.error('Error:', error));
  }, [searchTerm]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };
  const [formData, setFormData] = useState({
    userid: '',
    email: '',
    first_name: '',
    last_name: '',
    bright_id: '',

  });

  const [selectedCustomerDetails, setSelectedCustomerDetails] = useState(null);

  const fetchCustomersDetails = (customerNonce, forEdit = false) => {
    const protocolpluspath = () => window.location.protocol + '//' + window.location.host + '/';

    const eid = 'MTY1YTU0YzI2YTY4ODI='
    const url = protocolpluspath() + 'admin/getData.php?eid=' + eid + '&type=customer&id=' + encodeURIComponent(customerNonce);

    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        console.log('API Response:', data);
        if (data.success && data.data.response) {
          if (forEdit) {
            setSelectedCustomerForEdit(data.data.response[0]);
            setShowEditPopup(true);
          } else {
            setSelectedCustomerDetails(data.data.response[0]);
          }
        }
      })
      .catch(error => {
        console.error('Error processing data:', error);
      });
  };


  const deleteCustomer = (customerNonce) => {
    const eid = 'MTY1YTU0YzI2YTY4ODI=';

    const payload = {
      eid,
      nonce: customerNonce,
      endpoint: "v1/customer/delete"
    };

    fetch('/admin/postData.php', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload)
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        console.log('API Response:', data);
        if (data.success) {
          console.log('Customer deleted successfully');
          window.location.reload();
        }
      })
      .catch(error => {
        console.error('Error processing data:', error);
      });
  };


  function CustomersPopup({ customer, onClose }) {
    const customerDetails = customer.customer;
    return (
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
        <div className="bg-white p-5 rounded-lg shadow-lg w-full max-w-lg">
          <h2 className="text-lg font-semibold mb-4">View Details: {customerDetails.customer_number}</h2>
          {
            Object.keys(customerDetails).map((key) => {
              // skip keys don't want to show
              if (key === 'nonce' || key === 'customer_name') {
                return null;
              }
              return (
                <p key={key}>
                  {key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}: {customerDetails[key]}
                </p>
              );
            })
          }
          <div className="flex justify-end mt-6">
            <button className="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-700" onClick={onClose}>Close</button>
          </div>
        </div>
      </div>
    );
  }

  //  delete customer 
  const DeletePopup = ({ onClose, onDelete }) => (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
      <div className="bg-white p-5 rounded-lg shadow-lg text-center">
        <h2 className="text-xl font-bold mb-4">Delete Customer</h2>
        <p>You are about to delete this customer, are you sure?</p>
        <div className="flex justify-center gap-4 mt-4">
          <button onClick={onDelete} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
            Delete
          </button>
          <button onClick={onClose} className="bg-gray-300 hover:bg-gray-500 text-black font-bold py-2 px-4 rounded">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
  const [showPopup, setShowPopup] = useState(false);

  const handleDeleteClick = (nonce) => {
    setSelectedCustomerNonce(nonce);
    setShowPopup(true);
  };



  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleConfirmDelete = () => {
    if (selectedCustomerNonce) {
      deleteCustomer(selectedCustomerNonce);
      setShowPopup(false);
    }
  };

  const { responseMessage, setResponseMessage } = useContext(ResponseContext);

  const updateCustomer = (updatedCustomerData) => {
    const payload = {

      nonce: selectedCustomerForEdit.customer.nonce,
      first_name: updatedCustomerData.first_name,
      last_name: updatedCustomerData.last_name,
      company: updatedCustomerData.company,
      date_of_birth: updatedCustomerData.date_of_birth,
      customer_number: updatedCustomerData.customer_number,
      phone: updatedCustomerData.phone,
      email: updatedCustomerData.email,

      mailing_address: updatedCustomerData.mailing_address,
      mailing_street1: updatedCustomerData.mailing_street1,
      mailing_street2: updatedCustomerData.mailing_street2,
      mailing_city: updatedCustomerData.mailing_city,
      mailing_state: updatedCustomerData.mailing_state,
      mailing_zip: updatedCustomerData.mailing_zip,
      service_address: updatedCustomerData.service_address,
      service_street1: updatedCustomerData.service_street1,
      service_street2: updatedCustomerData.service_street2,
      service_city: updatedCustomerData.service_city,
      service_state: updatedCustomerData.service_state,
      service_zip: updatedCustomerData.service_zip,

      catalog_1: updatedCustomerData.catalog_1,
      catalog_2: updatedCustomerData.catalog_2,
      catalog_3: updatedCustomerData.catalog_3,
      catalog_4: updatedCustomerData.catalog_4,
      catalog_5: updatedCustomerData.catalog_5,

      cardholdername: updatedCustomerData.cardholdername,
      street: updatedCustomerData.street,
      city: updatedCustomerData.city,
      state: updatedCustomerData.state,
      zip: updatedCustomerData.zip,
      amount: updatedCustomerData.amount,
      tax: updatedCustomerData.tax,

      eid: 'MTY1YTU0YzI2YTY4ODI=',
      endpoint: "v1/customer/update",

    };

    fetch('/admin/postData.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload)
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        console.log('API Response:', data);
        if (data.success) {
          const message = data.response === "OK" ? "Completed Successfully" : data.response;
          setResponseMessage(message);
        }
      })
      .catch(error => {
        console.error('Error processing data:', error);
        setResponseMessage("Error processing data");
      });
  };





  // edit popup
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [selectedCustomerForEdit, setSelectedCustomerForEdit] = useState(null);

  function EditCustomerPopup({ customer, onClose }) {
    const [editedCustomer, setEditedCustomer] = useState(customer.customer);
    const [activeTab, setActiveTab] = React.useState("contact");
    const catalogData = selectedCustomerForEdit?.catalog_enum;


    // update customer confirmation popup
    const [showUpdateCustomerPopup, setShowUpdateCustomerPopup] = useState(false);

    const updateCustomerPopup = () => (
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
        <div className="bg-white p-5 rounded-lg shadow-lg text-center">
          <h2 className="text-xl font-bold mb-4">Update Customer</h2>
          <p>You are about to Update this customer, are you sure?</p>
          <div className="flex justify-center gap-4 mt-4">
            <button onClick={cancelUpdate} className="bg-gray-300 hover:bg-gray-500 text-black font-bold py-2 px-4 rounded">
              Cancel
            </button>
            <button onClick={confirmUpdate} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
              Update
            </button>
          </div>
        </div>
      </div>
    );


    const handleInputChange = (e) => {
      setEditedCustomer({ ...editedCustomer, [e.target.name]: e.target.value });
    };

    const handleUpdateClick = () => {
      setShowUpdateCustomerPopup(true);
    };

    const confirmUpdate = () => {
      const updatedCustomerData = {
        ...editedCustomer,
        nonce: customer?.customer.nonce,
      };

      updateCustomer(updatedCustomerData);
      setShowUpdateCustomerPopup(false);
    };

    const cancelUpdate = () => {
      setShowUpdateCustomerPopup(false);
    };


    useEffect(() => {
      if (catalogData) {
        const newEditedCustomer = { ...editedCustomer };
        catalogData.forEach((catalog, index) => {
          const catalogKey = Object.keys(catalog)[0];
          if (!newEditedCustomer[catalogKey]) {
            newEditedCustomer[catalogKey] = catalog[catalogKey][0];
          }
        });
        setEditedCustomer(newEditedCustomer);
      }
    }, [catalogData]);

    return (
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-start pt-10">
        <div className="bg-white p-5 rounded-lg shadow-lg w-full max-w-2xl max-h-[calc(100%-80px)] overflow-y-auto">

          <h2 className="text-lg font-semibold mb-4">Edit Customer: {editedCustomer.customer_number}</h2>

          <div className="flex justify-between items-center mb-2">
            <div className="flex-1 mr-2">
              <label className="text-sm font-semibold">First Name:</label>
              <input
                placeholder="---"
                className="w-full input-style"
                type="text"
                name="first_name"
                value={editedCustomer.first_name}
                onChange={handleInputChange}
              />
            </div>

            <div className="flex-1 ml-2">
              <label className="text-sm font-semibold">Last Name:</label>
              <input
                placeholder="---"
                className="w-full input-style"
                type="text"
                name="last_name"
                value={editedCustomer.last_name}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="flex justify-between items-center mb-2">
            <div className="flex-1 mr-2">
              <label className="text-sm font-semibold">Company:</label>
              <input
                placeholder="---"
                className="w-full input-style"
                type="text"
                name="company"
                value={editedCustomer.company}
                onChange={handleInputChange}
              />
            </div>

            <div className="flex-1 ml-2">
              <label className="text-sm font-semibold">Date Of Birth:</label>
              <input
                placeholder="---"
                className="w-full input-style"
                type="text"
                name="date_of_birth"
                value={editedCustomer.date_of_birth}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <label className="text-sm font-semibold">Customer #:</label>
          <input
            placeholder="---"
            className="mb-2 w-full input-style"
            type="text"
            name="customer_number"
            value={editedCustomer.customer_number}
            onChange={handleInputChange}
          />

          <div className="flex justify-center mt-4 border-b border-gray-300">
            <button
              className={`py-2 px-4 ${activeTab === "contact" ? "text-red-600 border-b-2 border-red-600" : "text-gray-500"} rounded-t`}
              onClick={() => setActiveTab("contact")}
            >
              Contact
            </button>
            <button
              className={`py-2 px-4 ml-2 ${activeTab === "mailing" ? "text-red-600 border-b-2 border-red-600" : "text-gray-500"} rounded-t`}
              onClick={() => setActiveTab("mailing")}
            >
              Mailing Address
            </button>

            <button
              className={`py-2 px-4 ml-2 ${activeTab === "service-address" ? "text-red-600 border-b-2 border-red-600" : "text-gray-500"} rounded-t`}
              onClick={() => setActiveTab("service-address")}
            >
              Service Address
            </button>

            <button
              className={`py-2 px-4 ml-2 ${activeTab === "catalogs" ? "text-red-600 border-b-2 border-red-600" : "text-gray-500"} rounded-t`}
              onClick={() => setActiveTab("catalogs")}
            >
              Catalogs
            </button>
          </div>


          <div className="mt-4">
            {activeTab === "contact" && (
              <div>
                <div className="flex justify-between items-center mb-2">
                  <div className="flex-1 mr-2">
                    <label className="text-sm font-semibold">Phone #:</label>
                    <input
                      placeholder="---"
                      className="w-full input-style"
                      type="text"
                      name="phone"
                      value={editedCustomer.phone}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="flex-1 ml-2">
                    <label className="text-sm font-semibold">Email:</label>
                    <input
                      placeholder="---"
                      className="w-full input-style"
                      type="text"
                      name="email"
                      value={editedCustomer.email}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
            )}
            {activeTab === "mailing" && (
              <div>
                <label className="text-sm font-semibold">Mailing Adress Nickname:</label>
                <input
                  placeholder="---"
                  className="mb-2 w-full input-style"
                  type="text"
                  name="mailing_address"
                  value={editedCustomer.mailing_address}
                  onChange={handleInputChange}
                />

                <div>
                  <div className="flex justify-between items-center mb-2">
                    <div className="flex-1 mr-2">
                      <label className="text-sm font-semibold">Street 1:</label>
                      <input
                        placeholder="---"
                        className="w-full input-style"
                        type="text"
                        name="mailing_street1"
                        value={editedCustomer.mailing_street1}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="flex-1 ml-2">
                      <label className="text-sm font-semibold">Street 2:</label>
                      <input
                        placeholder="---"
                        className="w-full input-style"
                        type="text"
                        name="mailing_street2"
                        value={editedCustomer.mailing_street2}
                        onChange={handleInputChange}
                      />
                    </div>


                  </div>
                </div>

                <div>
                  <div className="flex justify-between items-center mb-2">
                    <div className="flex-1 mr-2">
                      <label className="text-sm font-semibold">City:</label>
                      <input
                        placeholder="---"
                        className="w-full input-style"
                        type="text"
                        name="mailing_city"
                        value={editedCustomer.mailing_city}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="flex-1 ml-2">
                      <label className="text-sm font-semibold">State:</label>
                      <input
                        placeholder="---"
                        className="w-full input-style"
                        type="text"
                        name="mailing_state"
                        value={editedCustomer.mailing_state}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>

                <label className="text-sm font-semibold">Zip:</label>
                <input
                  placeholder="---"
                  className="mb-2 w-full input-style"
                  type="text"
                  name="mailing_zip"
                  value={editedCustomer.mailing_zip}
                  onChange={handleInputChange}
                />
              </div>
            )}
            {activeTab === "service-address" && (
              <div>
                <label className="text-sm font-semibold">Mailing Adress Nickname:</label>
                <input
                  placeholder="---"
                  className="mb-2 w-full input-style"
                  type="text"
                  name="service_address"
                  value={editedCustomer.service_address}
                  onChange={handleInputChange}
                />

                <div>
                  <div className="flex justify-between items-center mb-2">
                    <div className="flex-1 mr-2">
                      <label className="text-sm font-semibold">Street 1:</label>
                      <input
                        placeholder="---"
                        className="w-full input-style"
                        type="text"
                        name="service_street1"
                        value={editedCustomer.service_street1}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="flex-1 ml-2">
                      <label className="text-sm font-semibold">Street 2:</label>
                      <input
                        placeholder="---"
                        className="w-full input-style"
                        type="text"
                        name="service_street2"
                        value={editedCustomer.service_street2}
                        onChange={handleInputChange}
                      />
                    </div>


                  </div>
                </div>

                <div>
                  <div className="flex justify-between items-center mb-2">
                    <div className="flex-1 mr-2">
                      <label className="text-sm font-semibold">City:</label>
                      <input
                        placeholder="---"
                        className="w-full input-style"
                        type="text"
                        name="service_city"
                        value={editedCustomer.service_city}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="flex-1 ml-2">
                      <label className="text-sm font-semibold">State:</label>
                      <input
                        placeholder="---"
                        className="w-full input-style"
                        type="text"
                        name="service_state"
                        value={editedCustomer.service_state}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>

                <label className="text-sm font-semibold">Zip:</label>
                <input
                  placeholder="---"
                  className="mb-2 w-full input-style"
                  type="text"
                  name="service_zip"
                  value={editedCustomer.service_zip}
                  onChange={handleInputChange}
                />
              </div>
            )}
            {activeTab === "catalogs" && catalogData && (
              <div>
                {catalogData.map((catalog, index) => {
                  const catalogKey = Object.keys(catalog)[0];
                  return (
                    <div key={index}>
                      <label className="text-sm font-semibold">{`Catalog ${index + 1}:`}</label>
                      <select
                        name={catalogKey}
                        value={editedCustomer[catalogKey]}
                        onChange={handleInputChange}
                        className="w-full input-style"
                      >
                        {catalog[catalogKey].map((option, optionIndex) => (
                          <option key={optionIndex} value={option}>{option}</option>
                        ))}
                      </select>
                    </div>
                  );
                })}
              </div>
            )}

          </div>


          <div className="flex justify-end mt-6">
            <button className="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2" onClick={onClose}>Close</button>
            <button
              className="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-700"
              onClick={handleUpdateClick}
            >
              Update
            </button>
            {showUpdateCustomerPopup && updateCustomerPopup()}
          </div>

          {/* <div className="mr-auto text-sm text-gray-600">
            {responseMessage && (
              <div className={
                responseMessage === "NO CATALOGS SELECTED"
                  ? "bg-red-100 text-red-800 border border-red-400 p-2 rounded mt-2 flex justify-center items-center text-center"
                  : "bg-green-100 text-green-800 border border-green-400 p-2 rounded mt-2 flex justify-center items-center text-center"
              }>
                {responseMessage}
              </div>
            )}
          </div> */}

        </div>
      </div>
    );
  }

  const handleCustomerClick = (customerNonce) => {
    fetchCustomersDetails(customerNonce);
  };

  const handleEditClick = (customerNonce) => {
    fetchCustomersDetails(customerNonce, true);
  };

  // getData type enum (catalogs)
  const fetchCatalogs = () => {
    const protocolpluspath = () => window.location.protocol + '//' + window.location.host + '/';
    const eid = 'MTY1YTU0YzI2YTY4ODI=';
    const url = protocolpluspath() + 'admin/getData.php?eid=' + eid + '&type=enum';

    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('responsenot ok');
        }
        return response.json();
      })
      .then(data => {
        if (data.success && data.data && data.data.response) {
          setCatalogOptions(data.data.response);
        }
      })
      .catch(error => {
        console.error('Error processing data:', error);
      });
  };
  const [catalogOptions, setCatalogOptions] = useState([]);

  const [catalog1, setCatalog1] = useState("Select");
  const [catalog2, setCatalog2] = useState("Select")
  const [catalog3, setCatalog3] = useState("Select")
  const [catalog4, setCatalog4] = useState("Select")
  const [catalog5, setCatalog5] = useState("Select")

  const handleCatalogChange = (catalogNumber, newValue) => {
    switch (catalogNumber) {
      case 1: setCatalog1(newValue); break;
      case 2: setCatalog2(newValue); break;
      case 3: setCatalog3(newValue); break;
      case 4: setCatalog4(newValue); break;
      case 5: setCatalog5(newValue); break;
    }
  };

  // CREATE CUSTOMER

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [company, setCompany] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [customerNumber, setCustomerNumber] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');

  const [mailing_address, setMailing_address] = useState('');
  const [mailing_street1, setMailing_street1] = useState('');
  const [mailing_street2, setMailing_street2] = useState('');
  const [mailing_city, setMailing_city] = useState('');
  const [mailing_state, setMailing_state] = useState('');
  const [mailing_zip, setMailing_zip] = useState('');

  const [service_address, setService_address] = useState('');
  const [service_street1, setService_street1] = useState('');
  const [service_street2, setService_street2] = useState('');
  const [service_city, setService_city] = useState('');
  const [service_state, setService_state] = useState('');
  const [service_zip, setService_zip] = useState('');

  const [cardholdername, setCardholdername] = useState('');
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [amount, setAmount] = useState('');
  const [tax, setTax] = useState('');

  const handleCreateCustomerClick = () => {
    createCustomer();

  };





  const createCustomer = () => {
    const payload = {
      nonce: "",
      first_name: firstName,
      last_name: lastName,
      company: company,
      date_of_birth: dateOfBirth,
      customer_number: customerNumber,
      phone: phone,
      email: email,

      mailing_address: mailing_address,
      mailing_street1: mailing_street1,
      mailing_street2: mailing_street2,
      mailing_city: mailing_city,
      mailing_state: mailing_state,
      mailing_zip: mailing_zip,

      service_address: service_address,
      service_street1: service_street1,
      service_street2: service_street2,
      service_city: service_city,
      service_state: service_state,
      service_zip: service_zip,

      catalog_1: catalog1,
      catalog_2: catalog2,
      catalog_3: catalog3,
      catalog_4: catalog4,
      catalog_5: catalog5,

      cardholdername: cardholdername,
      street: street,
      city: city,
      state: state,
      zip: zip,
      amount: amount,
      tax: tax,


      eid: "MTY1YTU0YzI2YTY4ODI=",
      endpoint: "v1/customer/add",
    };

    fetch('/admin/postData.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload)
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        console.log('API Response:', data);
        if (data.success) {
          if (data.response === "NO CATALOGS SELECTED") {
            setErrorMessage('NO CATALOGS SELECTED');
          } else {
            setErrorMessage('Completed Successfully');
          }
        }
      })
      .catch(error => {
        console.error('Error processing data:', error);
        setErrorMessage('error ocureed while processing the request...');
      });

  }



  const { errorMessage, setErrorMessage } = useContext(ResponseContext);

  const [activeTab, setActiveTab] = React.useState("contact");
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="container-tokens"
    >
      {isFormVisible ? (
        <div>

          <div className="header-tokens flex justify-between">

            {/* elements on the left */}
            <div className="flex items-center">
              <h1 className="activity-log-title mr-4">Customer</h1>
              <input
                type="text"
                placeholder="Search by name"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="input-tokens"
              />
            </div>

            {/* elements on the right */}
            <div className="flex items-center">
              <button className="add-new-token" onClick={() => {
                setIsFormVisible(false);
                fetchCatalogs();
              }}>
                <AddnewIcon className="mr-1" />
                Add Customer
              </button>
            </div>

          </div>

          <div className="tokens-table-container">
            <table className="tokens-table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Customer Number</th>
                  <th>Name</th>
                  <th>Company</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {data && data.map((item, index) => (
                  <tr key={index}>
                    <td>{item.id}</td>
                    <td
                      className="text-blue-500 hover:underline cursor-pointer hover:text-blue-700"
                      onClick={() => handleCustomerClick(item.customer.nonce)}
                    >
                      {item.customer.customer_number}</td>
                    <td>{`${item.customer.first_name} ${item.customer.last_name}`}</td>
                    <td>{item.customer.company}</td>
                    <td>
                      <button
                        onClick={() => handleEditClick(item.customer.nonce)}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded cursor-pointer">
                        Edit
                      </button>
                    </td>
                    <td>
                      <button onClick={() => handleDeleteClick(item.customer.nonce)} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded cursor-pointer">
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>


              {showEditPopup && selectedCustomerForEdit && (
                <EditCustomerPopup
                  customer={selectedCustomerForEdit}
                  onClose={() => {
                    setShowEditPopup(false);
                    setSelectedCustomerForEdit(null);
                  }}
                />
              )}

              {showPopup && <DeletePopup onClose={handleClosePopup} onDelete={handleConfirmDelete} />}

              {selectedCustomerDetails && <CustomersPopup customer={selectedCustomerDetails} onClose={() => setSelectedCustomerDetails(null)} />}
            </table>
          </div>
        </div>

      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="flex justify-center w-full min-h-screen pt-10"
        >
          <div className="container mx-auto p-6 custom-width">

            <div>
              <div className="flex justify-between items-center py-6">
                <div>
                  <h1 className="token-title">Create a Customer</h1>
                  <span className="create-user-description"> (*)fields are mandatory to fill</span>
                </div>
                <button onClick={handleCreateCustomerClick} className="save-and-continue text-sm font-semibold">Save</button>
              </div>


              <div className="flex justify-between items-center mb-2">
                <div className="flex-1 mr-2">
                  <label className="text-sm font-semibold">First Name</label>
                  <input
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    placeholder="---"
                    className="w-full input-style"
                  />
                </div>

                <div className="flex-1 ml-2">
                  <label className="text-sm font-semibold">Last Name</label>
                  <input
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    placeholder="---"
                    className="w-full input-style"
                  />
                </div>
              </div>


              {/* Username and Password */}
              <div className="flex justify-between items-center mb-2">
                <div className="flex-1 mr-2">
                  <label className="text-sm font-semibold">Company</label>
                  <input
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                    placeholder="---"
                    className="w-full input-style"
                  />
                </div>

                <div className="flex-1 ml-2">
                  <label className="text-sm font-semibold">Date of Birth</label>
                  <input
                    value={dateOfBirth}
                    onChange={(e) => setDateOfBirth(e.target.value)}
                    placeholder="---"
                    className="w-full input-style"
                  />
                </div>
              </div>

              <label className="text-sm font-semibold">Customer #</label>
              <input
                value={customerNumber}
                onChange={(e) => setCustomerNumber(e.target.value)}
                placeholder="---"
                className="mb-2 w-full input-style"
              />


              <div className="flex justify-center mt-4 border-b border-gray-300">
                <button
                  className={`py-2 px-4 ${activeTab === "contact" ? "text-red-600 border-b-2 border-red-600" : "text-gray-500"} rounded-t`}
                  onClick={() => setActiveTab("contact")}
                >
                  Contact
                </button>
                <button
                  className={`py-2 px-4 ml-2 ${activeTab === "mailing" ? "text-red-600 border-b-2 border-red-600" : "text-gray-500"} rounded-t`}
                  onClick={() => setActiveTab("mailing")}
                >
                  Mailing Address
                </button>

                <button
                  className={`py-2 px-4 ml-2 ${activeTab === "service-address" ? "text-red-600 border-b-2 border-red-600" : "text-gray-500"} rounded-t`}
                  onClick={() => setActiveTab("service-address")}
                >
                  Service Address
                </button>

                <button
                  className={`py-2 px-4 ml-2 ${activeTab === "catalogs" ? "text-red-600 border-b-2 border-red-600" : "text-gray-500"} rounded-t`}
                  onClick={() => setActiveTab("catalogs")}
                >
                  Catalogs
                </button>
              </div>

              <div className="mt-4">
                {activeTab === "contact" && (
                  <div>
                    <div className="flex justify-between items-center mb-2">
                      <div className="flex-1 mr-2">
                        <label className="text-sm font-semibold">Phone #:</label>
                        <input
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          placeholder="---"
                          className="w-full input-style"
                          type="text"
                        />
                      </div>

                      <div className="flex-1 ml-2">
                        <label className="text-sm font-semibold">Email:</label>
                        <input
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="---"
                          className="w-full input-style"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                )}
                {activeTab === "mailing" && (
                  <div>
                    <label className="text-sm font-semibold">Mailing Adress Nickname:</label>
                    <input
                      value={mailing_address}
                      onChange={(e) => setMailing_address(e.target.value)}
                      placeholder="---"
                      className="mb-2 w-full input-style"
                      type="text"
                    />

                    <div>
                      <div className="flex justify-between items-center mb-2">
                        <div className="flex-1 mr-2">
                          <label className="text-sm font-semibold">Street 1:</label>
                          <input
                            value={mailing_street1}
                            onChange={(e) => setMailing_street1(e.target.value)}
                            placeholder="---"
                            className="w-full input-style"
                            type="text"
                          />
                        </div>

                        <div className="flex-1 ml-2">
                          <label className="text-sm font-semibold">Street 2:</label>
                          <input
                            value={mailing_street2}
                            onChange={(e) => setMailing_street2(e.target.value)}
                            placeholder="---"
                            className="w-full input-style"
                            type="text"
                          />
                        </div>


                      </div>
                    </div>

                    <div>
                      <div className="flex justify-between items-center mb-2">
                        <div className="flex-1 mr-2">
                          <label className="text-sm font-semibold">City:</label>
                          <input
                            value={mailing_city}
                            onChange={(e) => setMailing_city(e.target.value)}
                            placeholder="---"
                            className="w-full input-style"
                            type="text"
                          />
                        </div>

                        <div className="flex-1 ml-2">
                          <label className="text-sm font-semibold">State:</label>
                          <input
                            value={mailing_state}
                            onChange={(e) => setMailing_state(e.target.value)}
                            placeholder="---"
                            className="w-full input-style"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>

                    <label className="text-sm font-semibold">Zip:</label>
                    <input
                      value={mailing_zip}
                      onChange={(e) => setMailing_zip(e.target.value)}
                      placeholder="---"
                      className="mb-2 w-full input-style"
                      type="text"
                    />
                  </div>
                )}

                {activeTab === "service-address" && (
                  <div>
                    <label className="text-sm font-semibold">Mailing Adress Nickname:</label>
                    <input
                      value={service_address}
                      onChange={(e) => setService_address(e.target.value)}
                      placeholder="---"
                      className="mb-2 w-full input-style"
                      type="text"
                    />

                    <div>
                      <div className="flex justify-between items-center mb-2">
                        <div className="flex-1 mr-2">
                          <label className="text-sm font-semibold">Street 1:</label>
                          <input
                            value={service_street1}
                            onChange={(e) => setService_street1(e.target.value)}
                            placeholder="---"
                            className="w-full input-style"
                            type="text"
                          />
                        </div>

                        <div className="flex-1 ml-2">
                          <label className="text-sm font-semibold">Street 2:</label>
                          <input
                            value={service_street2}
                            onChange={(e) => setService_street2(e.target.value)}
                            placeholder="---"
                            className="w-full input-style"
                            type="text"
                          />
                        </div>


                      </div>
                    </div>

                    <div>
                      <div className="flex justify-between items-center mb-2">
                        <div className="flex-1 mr-2">
                          <label className="text-sm font-semibold">City:</label>
                          <input
                            value={service_city}
                            onChange={(e) => setService_city(e.target.value)}
                            placeholder="---"
                            className="w-full input-style"
                            type="text"
                          />
                        </div>

                        <div className="flex-1 ml-2">
                          <label className="text-sm font-semibold">State:</label>
                          <input
                            value={service_state}
                            onChange={(e) => setService_state(e.target.value)}
                            placeholder="---"
                            className="w-full input-style"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>

                    <label className="text-sm font-semibold">Zip:</label>
                    <input
                      value={service_zip}
                      onChange={(e) => setService_zip(e.target.value)}
                      placeholder="---"
                      className="mb-2 w-full input-style"
                      type="text"
                    />
                  </div>
                )}

                {activeTab === "catalogs" && (
                  <div>
                    <label className="text-sm font-semibold">Catalog 1</label>
                    <select value={catalog1} className="w-full input-style" onChange={(e) => handleCatalogChange(1, e.target.value)}>
                      {catalogOptions.map((option, index) => (
                        <option key={index} value={option}>{option}</option>
                      ))}
                    </select>

                    <label className="text-sm font-semibold">Catalog 2</label>
                    <select value={catalog2} className="w-full input-style" onChange={(e) => handleCatalogChange(2, e.target.value)}>
                      {catalogOptions.map((option, index) => (
                        <option key={index} value={option}>{option}</option>
                      ))}
                    </select>

                    <label className="text-sm font-semibold">Catalog 3</label>
                    <select value={catalog3} className="w-full input-style" onChange={(e) => handleCatalogChange(3, e.target.value)}>
                      {catalogOptions.map((option, index) => (
                        <option key={index} value={option}>{option}</option>
                      ))}
                    </select>

                    <label className="text-sm font-semibold">Catalog 4</label>
                    <select value={catalog4} className="w-full input-style" onChange={(e) => handleCatalogChange(4, e.target.value)}>
                      {catalogOptions.map((option, index) => (
                        <option key={index} value={option}>{option}</option>
                      ))}
                    </select>

                    <label className="text-sm font-semibold">Catalog 5</label>
                    <select value={catalog5} className="w-full input-style" onChange={(e) => handleCatalogChange(5, e.target.value)}>
                      {catalogOptions.map((option, index) => (
                        <option key={index} value={option}>{option}</option>
                      ))}
                    </select>
                  </div>
                )}

                {/* {errorMessage && (
                  <div className={
                    errorMessage === "NO CATALOGS SELECTED"
                      ? "bg-red-100 text-red-800 border border-red-400 p-2 rounded mt-2 flex justify-center items-center text-center"
                      : "bg-green-100 text-green-800 border border-green-400 p-2 rounded mt-2 flex justify-center items-center text-center"
                  }>
                    {errorMessage}
                  </div>
                )} */}

              </div>

            </div>
          </div>
        </motion.div >
      )}
    </motion.div >
  );
}
